import React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Home from './Home.jsx';

function QrHome() {
    const [searchParams, setSearchParams] = useSearchParams();
    //onload, just set demo data
    useEffect(() => {
        setSearchParams({ id: "demo", amount: 0, sig: "sig" })
        async function fetchBill() {
            const response = await fetch('/qr');
            const data = await response.json();
            //console.log(data)
        }
        fetchBill();
    }, [])

    return (
        <Home />
    );
}

export default QrHome;