import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Demo/Home.jsx';
import DemoHome from './components/Demo/DemoHome.jsx';
import QrHome from './components/Demo/QrHome.jsx';

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' exact element={<Home />} />
                    <Route path='/demo' exact element={<DemoHome />} />
                    <Route path='/qr' exact element={<QrHome />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
