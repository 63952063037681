import React from 'react';
import { useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import SuccessModal from './SuccessModal.jsx';
import ErrorModal from './ErrorModal.jsx';

const ratings = [{ rating_num: 1, star_name: "Poor" }, { rating_num: 2, star_name: "Average" }, { rating_num: 3, star_name: "Good" }, { rating_num: 4, star_name: "Excellent" }, { rating_num: 5, star_name: "Awesome" }];

function RateModal({ rate_modal, modalState, setRateModal }) {
    const [email, setEmail] = useState('');
    const [emailText, setEmailText] = useState('');
    const [ratingNum, setRatingNum] = useState();
    const [fName, setName] = useState('');
    const [lastName, setLName] = useState('');
    const [phoneNo, setPhoneNo] = useState();
    const [success_modal, setSuccessModal] = useState('closedModal');
    const [searchParams, setSearchParams] = useSearchParams();
    const [errorModalState, setErrorModalState] = useState('closedModal');

    //star ratings
    const ratingElements = useRef([]);


    //take user input
    const email_address = (event) => {
        const email = event.target.value;
        const fieldName = event.target.name;
        console.log(email, fieldName)
        setEmail((oldEmails) => {
            return { ...oldEmails, [fieldName]: email }
        })
    }

    const emailMessage = (event) => {
        const text = event.target.value;
        const fieldName = event.target.name;
        console.log(text, fieldName)
        setEmailText((oldEmails) => {
            return { ...oldEmails, [fieldName]: text }
        })
    };

    const reviewerName = (event) => {
        const name = event.target.value;
        const fieldName = event.target.name;
        console.log(name, fieldName)
        setName((oldName) => {
            return { ...oldName, [fieldName]: name }
        })
    }

    const reviewerLName = (event) => {
        const lName = event.target.value;
        const fieldName = event.target.name;
        console.log(lName, fieldName)
        setLName((oldLNames) => {
            return { ...oldLNames, [fieldName]: lName }
        })
    }

    const phoneNumber = (event) => {
        const number = event.target.value;
        const fieldName = event.target.name;
        //console.log(number, fieldName)
        setPhoneNo((oldNumbers) => {
            return { ...oldNumbers, [fieldName]: number }
        })
    }

    //set the user rating number;
    //based on key of rating star element we add/remove color to the star in ratingElements array
    const userRating = (rating_num, key) => {
        setRatingNum(rating_num)
        let star_elements = ratingElements.current.filter((element, index) => index <= 4);
        star_elements.forEach((element, idx) => {
            if (idx <= key && element !== null) {
                element.style.color = "orange";
            }
            else {
                element.style.color = "white";
            }
        })
    };


    //send user ratings
    const sendRating = async (e) => {
        e.preventDefault();
        let { email_address } = email;
        let { message } = emailText;
        let { reviewerName } = fName;
        let { reviewerLName } = lastName;
        let { cellphone } = phoneNo;
        let id = searchParams.get('id');
        let amount = searchParams.get('amount');
        let sig = searchParams.get('sig');
        let ratingData = {
            "Id": id,
            "Amount": amount,
            "Sig": sig,
            "emailAddress": email_address,
            "stars": `${ratingNum}`,
            "firstName": reviewerName,
            "lastName": reviewerLName,
            "cellNumber": cellphone,
            "comment": message
        }

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(ratingData)
        }

        await fetch('/api/Feedback/Submit', requestOptions)
            .then((res) => res.json())
            .then((data) => {
                //console.log(data)
                if (data.Success === true) {
                    setSuccessModal("success_modal");
                    setRateModal("closedModal");
                    setEmail('');
                    setEmailText('');
                    setRatingNum('');
                    setName('');
                    setLName('');
                    setPhoneNo('');
                }
                else {
                    setErrorModalState("success_modal")
                    setRateModal("closedModal")
                }
            })
            .catch((err) => console.log(err));
    }


    //close Modal
    const closeModalAside = (event) => {
        if (event.target.className === "modalMainContainer") {
            setRateModal('closedModal')
        }
    }

    return (
        <>
            <aside className="modalMainContainer" id={rate_modal} onClick={closeModalAside}>
                <div className="rate_us_container">
                    <h2 className="rate_us_heading">Rate Us</h2>
                    <form id="rate_us_form" onSubmit={sendRating}>
                        <h2 className="Rate_Us_text">How would you rate our service</h2>
                        <div id="rate_list">
                            {
                                ratings.map((rating_number, key) => {
                                    let { rating_num, star_name } = rating_number;
                                    return (
                                        <div className="star" key={key} ref={(elmnt) => ratingElements.current.push(elmnt)}>
                                            <i
                                                class="fa-regular fa-star"
                                                onClick={() => userRating(rating_num, key)}
                                            >
                                            </i>
                                            <span className="star_name">{star_name}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <input type="text" className="reviewerName" name="reviewerName" placeholder="Name" onChange={(event) => reviewerName(event)} required />
                        <input type="text" className="reviewerLName" name="reviewerLName" placeholder="Last Name" onChange={(event) => reviewerLName(event)} required />
                        <input type="text" className="cellphone" name="cellphone" placeholder="Cellphone" onChange={(event) => phoneNumber(event)} required />
                        <input type="text" className="email_address" name="email_address" placeholder="Email Address" onChange={(event) => email_address(event)} required />
                        <textarea rows="4" cols="50" name="message" placeholder="Comments (Optional)" className="messageRate" onChange={(event) => emailMessage(event)}>
                        </textarea>

                        <div className="form_submission">
                            <input type="submit" className="submit" value="Submit" />
                            <input type="button" className="submit" name="rate_modal" value="Cancel" onClick={modalState} />
                        </div>
                    </form>
                </div>
            </aside>

            <SuccessModal success_modal={success_modal} setSuccessModal={setSuccessModal} />
            <ErrorModal errorModalState={errorModalState} setErrorModalState={setErrorModalState} />
        </>
    );
}

export default RateModal;