import React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ResponseErrorModal from './ResponseErrorModal.jsx';


function PayModal({ pay_modal, modalState }) {
    const [payProvidersData, setPaymentProviders] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState('closedModal');
    const [ResponseErrorModalState, setResponseErrorModalState] = useState('closedModal');


    //on initial render, fetch paymentProvider links and details
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        const { id, amount, sig } = currentParams
        async function fetchPayProviders() {
            let paymentData = `?id=${id}&amount=${amount}&sig=${sig}`;

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" }
            }

            const response = await fetch(`/api/PaymentProvider/Load${paymentData}`, requestOptions)
            if (response.status === 200) {
                const data = await response.json();
                setPaymentProviders(data)
            } else {
                setResponseErrorModalState("ResponseErrorModalState");
                setModal("closedModal");
            }
        }
        fetchPayProviders();
    }, [])

    return (
        <>
            <aside id={pay_modal}>
                <div className="pay_container">
                    <h2 className="pay_heading">Choose a payment method</h2>
                    <div id="pay_options">

                        <ul className="ordered_items_list">
                        {payProvidersData?.map((keyword) => {
                            return (
                                <li className="pay-img-container" >

                                    <a href={keyword.Url} className="payImg">{keyword.Title}</a>
                                </li>
                            );
                        })}
                        </ul>

                    </div>

                    <button className="cancelPay" name="pay_modal" onClick={modalState}>Cancel</button>

                </div>
            </aside>
        </>
    );
}

export default PayModal;