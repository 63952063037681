import React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Home from './Home.jsx';

function DemoHome() {
    const [searchParams, setSearchParams] = useSearchParams();

    //onload, navigate to homepage
    useEffect(() => {
        setSearchParams({ id: "demo", amount: 37200, sig: "sig" })
        const id = "demo";
        const amount = 37200;
        const sig = "YTdjfyDBFNAKdufyBAiPPeefKLdjnf";
        async function fetchBill() {
            const reqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            };
            const response = await fetch(`api/TransactionInfo/Load?id=${id}&amount=${amount}$sig=${sig}`, reqOptions);
            const data = await response.json();
            
        }
        fetchBill();
    }, [])

    return (
        <>
            <Home />
        </>
    );
}

export default DemoHome;
