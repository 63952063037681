import React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './Home.css'
import EmailModal from './Demo_Modals/EmailModal.jsx';
import RateModal from './Demo_Modals/RateModal.jsx';
import PayModal from './Demo_Modals/PayModal.jsx';
import ResponseErrorModal from './Demo_Modals/ResponseErrorModal.jsx';


function Home() {
    const [modal, setModal] = useState('closedModal');
    const [rate_modal, setRateModal] = useState('closedModal');
    const [pay_modal, setPayModal] = useState('closedModal');
    const [ResponseErrorModalState, setResponseErrorModalState] = useState('closedModal');
    const [billData, setBillData] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();


    //on initial render, get url parameters
    //if no parameters e.g undefined, send back to qrhub site
    //if there are parameters, send fetch bill data based on parameters
    //going to fetch bill data,send emails/ratings with parameters
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);

        if (typeof currentParams.id === "undefined") {
            setSearchParams({ id: "demo", amount: 37200, sig: "sig" })
            navigate('/demo')
        }
        else {
            console.warn("FetchBill");

            const { id, amount, sig } = currentParams;
            async function fetchBill() {
                const reqOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }
                };
                const response = await fetch(`api/TransactionInfo/Load?id=${id}&amount=${amount}$sig=${sig}`, reqOptions);
                if (response.status === 200) {
                    const data = await response.json();
                    setBillData(data)
                } else {
                    setResponseErrorModalState("ResponseErrorModalState");
                    setModal("closedModal");
                }


            }
            fetchBill();
        }
        /* if (typeof currentParams.id === "undefined") {
             if (qr_location === null) {
                 setSearchParams({ id: "demo", amount: 37200, sig: "sig" })
             }
             else {
                 setSearchParams(qr_location)
             }
         }
         else {
             const { id, amount, sig } = currentParams;
             async function fetchBill() {
                 const reqOptions = {
                     method: 'POST',
                     headers: { 'Content-Type': 'application/json' }
                 };
                 const response = await fetch(`api/TransactionInfo/Load?id=${id}&amount=${amount}$sig=${sig}`, reqOptions);
                 const data = await response.json();
                 setBillData(data)
             }
             fetchBill();
         }*/

    }, [searchParams])

    const modalState = (e) => {
        const modalName = e.target.name;
        console.warn("In Modal Switch");
        console.warn(e.target.name);
        console.warn(rate_modal);
        switch (modalName) {
            case 'invoice_modal':
                if (modal === 'closedModal') {
                    setModal('email_invoice_modal');
                }
                else {
                    setModal('closedModal');
                }
                break;
            case 'rate_modal':
                if (rate_modal === 'closedModal') {
                    setRateModal('rate_us_modal');
                }
                else {
                    setRateModal('closedModal');
                }
                break;
            case 'pay_modal':
                if (pay_modal === 'closedModal') {
                    setPayModal('pay_now_modal');
                }
                else {
                    setPayModal('closedModal');
                }
        }
    }

    if (typeof billData === "undefined") {
        return <></>
    }
    else {

        const navigateLoyalty = () => {
            // 👇️ navigate to /
            window.open(billData.LoyaltyProviderURL, '_blank');

        };

        const navigateCustomLoyalty = () => {
            // 👇️ navigate to /
            window.open(billData.CustomLoyaltyProviderURL, '_blank');

        };

        let backGroundImage = billData.BackgroundImage;
        let storeTel = "tel: " + billData.ContactNumber;

        const navigateReview = () => {

            window.location.href = billData.CustomReviewUrl;
        };

        return (
            <main id="demo_main_container">


                <section id="demo_banner" className="demo_banner" style={{ background: `url(${backGroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <img src={billData.LogoUrl} className="phoneImage" />
                </section>

                <section id="storename-section">
                    <div class="storeName-container">
                        <p><a className="storeName" href={billData.StoreWebsite} target="_blank">{billData.StoreName}</a></p>
                        <p className="storeTagline">{billData.StoreTagLine}</p>

                    </div>

                </section>

                <section id="details-section">
                    

                    <div id="rate_invoice">
                        <div className="rate_invoice_btns">
                            <button className="email_invoice" name="invoice_modal" onClick={modalState}>Email Invoice</button>
                            <button className="rate_us" name="rate_modal" onClick={billData.CustomReviewEnabled ? navigateReview : modalState}>
                                Rate Us
                            </button>
                        </div>
                        <div className="invoice_details">
                            <div className="invoice">
                                <p className="invoice_number">
                                    <span className="invoice_description">Invoice</span> :
                                    <span className="invoice_total">{billData.InvoiceNumber}</span>
                                </p>
                                <p className="table_number">
                                    <span className="invoice_description">Table</span> :
                                    <span className="invoice_total">{billData.TableNumber}</span>
                                </p>
                                <p className="tax">
                                    <span className="invoice_description">Tax</span> :
                                    <span className="invoice_total">{billData.Currency}{billData.VatAmount}</span>
                                </p>
                            </div>
                            <div className="total_price">
                                <p className="price_text">{billData.Currency}{billData.TotalGrossAmount}</p>
                            </div>
                        </div>
                    </div>

                    <div className="loyalty_payNow_btns">
                        <button className="loyalty" onClick={navigateCustomLoyalty}>
                            Loyalty
                        </button>
                        <button className="pay_now" name="pay_modal" onClick={modalState}>Pay Now</button>
                    </div>

                    <ul className="ordered_items_list">
                        {
                            billData.BasketItems.map((item, idx) => {
                                const { Item, Quantity, Amount } = item;
                                return (
                                    <li className="ordered_item" key={idx}>
                                        <span className="quantity">{Quantity}</span>
                                        <p className="item-name">{Item}</p>
                                        <span className="item_price">{billData.Currency}{Amount}</span>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </section>



                <EmailModal modal={modal} modalState={modalState} setModal={setModal} />
                <RateModal rate_modal={rate_modal} modalState={modalState} setRateModal={setRateModal} />
                <PayModal pay_modal={pay_modal} modalState={modalState} />
                <footer>
                    <p className="tel">Tel: <a href={storeTel} className="tel"> {billData.ContactNumber}</a> </p>
                    <p className="date">Date: {billData.TransactionDateTime}</p>
                </footer>
            </main>
        );





    }
}

export default Home;