import React from 'react';
import { useState, useEffect } from 'react';


function ErrorModal({ errorModalState, setErrorModalState }) {

    const closeSuccessModal = (e) => {
        setErrorModalState("closedModal")
    }

    return (
        <>
            <aside id={errorModalState}>
                <div className="success_modal_container">
                    <div className="tick">
                        <div className="success_ring">
                            <i class="fa-solid fa-circle-exclamation"></i>
                        </div>
                    </div>
                    <h3 className="thanks_heading">Error</h3>
                    <p className="thanks_message">
                       There was an issue in submitting the form.
                    </p>
                    <button className="thanks_btn" name="thanks" onClick={closeSuccessModal}>Cancel</button>
                </div>
            </aside>
        </>
    );
}

export default ErrorModal;