import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EmailSuccessModal from './EmailSuccessModal.jsx';
import ErrorModal from './ErrorModal.jsx';

function EmailModal({ modal, modalState, setModal }) {
    const [email, setEmail] = useState('');
    const [emailText, setEmailText] = useState('');
    const [success_modal, setSuccessModal] = useState('closedModal');
    const [searchParams, setSearchParams] = useSearchParams();
    const [formContainer, setFormContainer] = useState(false);
    const [errorModalState, setErrorModalState] = useState('closedModal');

    const email_address = (event) => {
        const email = event.target.value;
        const fieldName = event.target.name;
        console.log(email, fieldName)
        setEmail((oldEmails) => {
            return { ...oldEmails, [fieldName]: email }
        })
    }

    const emailMessage = (event) => {
        const text = event.target.value;
        const fieldName = event.target.name;
        console.log(text, fieldName)
        setEmailText((oldEmails) => {
            return { ...oldEmails, [fieldName]: text }
        })
    };

    //When sending email, get data from url, send email based on that data
    const sendEmail = async (event) => {
        event.preventDefault();
        let id = searchParams.get('id');
        let amount = searchParams.get('amount');
        let sig = searchParams.get('sig');
        let { email_address } = email;
        let { message } = emailText;
        let emailData = {
            "Id": id,
            "Amount": amount,
            "Sig": sig,
            "emailAddress": email_address,
            "notes": message
        };
        await fetch('api/Email/Send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailData)
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.Success === true) {
                    setSuccessModal("success_modal");
                    setModal("closedModal");
                    setEmail('');
                    setEmailText('');
                }
                else {
                    setErrorModalState("success_modal");
                    setModal("closedModal");
                }
            })
            .catch((err) => console.log(err));
    };


    //close modal without clicking submit button
    const closeModalAside = (event) => {
        if (event.target.className === "modalMainContainer") {
            setModal('closedModal')
        }
    }

        return (
            <>
                <aside className="modalMainContainer" id={modal} onClick={(event) => closeModalAside(event)}>
                    <div className="email_invoice_container">
                        <h2 className="invoice_heading">Email Invoice</h2>
                        <form id="invoice_form" onSubmit={sendEmail}>
                            <input type="text" className="email_address" name="email_address" placeholder="Email Address" onChange={(event) => email_address(event)} required />
                            <textarea rows="4" cols="50" placeholder="Notes (Optional)" name="message" className="message" onChange={(event) => emailMessage(event)}>
                            </textarea>

                            <div className="form_submission">
                                <input type="submit" className="submit" value="Submit" />
                                <input type="button" className="submit" name="invoice_modal" value="Cancel" onClick={modalState} />
                            </div>
                        </form>
                    </div>
                </aside>
                <EmailSuccessModal success_modal={success_modal} setSuccessModal={setSuccessModal} />
                <ErrorModal errorModalState={errorModalState} setErrorModalState={setErrorModalState} />
            </>
        );
 }

export default EmailModal;