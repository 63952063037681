import React from 'react';
import { useState, useEffect } from 'react';


function SuccessModal({ success_modal, setSuccessModal }) {

    const closeSuccessModal = (e) => {
        setSuccessModal("closedModal")
    }

    return (
        <>
            <aside id={success_modal}>
                <div className="success_modal_container">
                    <div className="tick">
                        <div className="success_ring">
                            <i className="fa-solid fa-check"></i>
                        </div>
                    </div>
                    <h3 className="thanks_heading">Thank You!</h3>
                    <p className="thanks_message">
                        We appreciate that you've taken the time to give us your feedback!
                    </p>
                    <button className="thanks_btn" name="thanks" onClick={closeSuccessModal}>OK</button>
                </div>
            </aside>
        </>
    );
}

export default SuccessModal;